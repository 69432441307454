<script>
import HeadingComponent from './components/HeadingComponent.vue'
import { ref } from 'vue'

export default {
  name: 'App',
  components: {
    HeadingComponent
  },
  setup() {
    const title = ref("Passagierdaten eingeben")
    const type = ref(1)
    const form = ref({
      name: "",
      surname: "",
      passportNumber: "",
      citizenship: "",
      dateOfValidity: ""
    })

    return {
      title, type, form
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <HeadingComponent :title=title :type=type />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form>
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" v-model="form.name" class="form-control" id="name" aria-describedby="name"
              placeholder="Enter Name">
          </div>
          <div class="form-group">
            <label for="surname">Password</label>
            <input type="text" v-model="form.surname" class="form-control" id="surname" placeholder="Enter Surname">
          </div>
          <div class="form-group">
            <label for="passport-number">Passport Number</label>
            <input type="text" v-model="form.passportNumber" class="form-control" id="passport-number"
              placeholder="Enter Passport Number">
          </div>
          <div class="form-group">
            <label for="citizenship">Citizenship</label>
            <input type="text" v-model="form.citizenship" class="form-control" id="citizenship"
              placeholder="Enter Citizenship">
          </div>
          <div class="form-group">
            <label for="date-of-validity">Date of validity</label>
            <input type="date" v-model="form.dateOfValidity" class="form-control" id="date-of-validity"
              placeholder="Enter Date of validity">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import './scss/styles.scss'
</style>
