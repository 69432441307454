<template>
    <h1 v-if="type === 1">{{ title }}</h1>
    <h2 v-if="type === 2">{{ title }}</h2>
    <h3 v-if="type === 3">{{ title }}</h3>
    <h4 v-if="type === 4">{{ title }}</h4>
    <h5 v-if="type === 5">{{ title }}</h5>
    <h6 v-if="type === 6">{{ title }}</h6>
</template>
<script>
export default {
    name: 'HeadingComponent',
    props: {
        title: String,
        type: { type: Number, required: true }
    }
}
</script>
<style scoped lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "lufthansa_office_headbold",serif;
    color: rgb(5, 22, 77)
}
</style>